<template>
    <div>
        <UiModalsSubscribeModal id="sbSubscribeModal" :blok="blok" :form-state="formState" @submit="submitForm" />
    </div>
</template>

<script lang="ts" setup>
import { useEmailSubscriptionStore } from '../../_base/store/emailSubscription';
import type { ISubscribeModal, ISubscribeModalFormState } from '~ui/types/components/modals/SubscribeModal';
import { useSearchStore } from '~/../_base/store/search';
const { blok } = defineProps<{ blok: ISubscribeModal }>();

const formState = ref<ISubscribeModalFormState>({
    success: false,
    isSubmitted: false,
    responseMsg: null,
});

const emailSubscriptionStore = useEmailSubscriptionStore();
const config = useRuntimeConfig();

// Get access to the router instance
const router = useRouter();
// Get access to the current route
const route = useRoute();
// Constant variable for access to search store
const searchStore = useSearchStore();

const updatePromoParam = () => {
    const currentQuery = route.query;

    const updatedQuery = {
        ...currentQuery,
        promo: blok.discountCode,
    };

    router.push({ query: updatedQuery as any });
    //set promo code into Searchbox
    searchStore.updatePromo(blok.discountCode);
};
const submitForm = async (formData: any) => {
    const email = formData.email;
    formState.value.isSubmitted = true;
    return await $fetch(`/api/subscribe`, {
        method: 'POST',
        body: { email, listId: config?.public.ITERABLE_LIST_ID, signupSource: blok.signupSource ?? 'headerModal' },
        onResponse({ response }) {
            if (response.ok) {
                emailSubscriptionStore.updateIsSubscribed(true);
                formState.value.responseMsg = '';
                formState.value.success = true;
                formState.value.isSubmitted = false;
                updatePromoParam();
            }
        },
        onResponseError({ response }) {
            if (response) {
                formState.value.isSubmitted = true;
                formState.value.success = false;
                formState.value.responseMsg = blok.defaultErrorMessage;
                console.error(response);
            }
        },
    });
};
</script>

<style lang="postcss" scoped></style>
